@import "./fonts.scss";
@import "./constants.scss";
@import "./base.scss";
@import "./modal.scss";
@import "./reports.scss";

.calendar {
    background: #2D303E;
    border: 1px solid #393C49;
    border-radius: 8px;
    overflow: hidden;

    .rdrDayNumber span {
        color: #fff;
    }

    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
        background: #EDA240;
        border: none;
    }

    .rdrDayNumber {
        z-index: 2;
    }

    .rdrMonthAndYearPickers select {
        color: #fff;
    }

    .rdrMonthAndYearPickers select {
        background: #EDA240;
        appearance: auto;
    }

    .rdrDateDisplayWrapper {
        background: #2D303E;
    }
}

*:disabled,
div:disabled {
    pointer-events: none;
    opacity: 0.6;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
    color: rgba(255, 255, 255, 0.5) !important;
    opacity: 0.6;
}

.technical-order-row {
    background-color: rgba(255, 68, 68, 0.5);
}

.multiple-payment-types-row {
    background-color: rgba(255, 68, 68, 0.5);
}

.times-div {
    font-size: 16px;
    font-weight: 500;
    width: 4.5em;
}

.bor_l {
    border-left: 1px solid rgba(217, 202, 202, 0.5);
    padding-left: 20px;
}

.row_radio {
    fieldset {
        display: flex;
        align-items: center;
        gap: 14px;
        flex-direction: row;
    }
}

.rowe_secr {
    display: flex;
    align-items: center;
    gap: 20px;
}