.container {
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    align-items: center;
}

.imageContainer {
    width: 122px;
    height: 122px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.image {
    height: 100%;
    user-select: none;
}

.addImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: rgba($color: #000000, $alpha: 0.3);
        transition: all 0.3s ease;
    }
}

.buttons {
    width: 170px;
}

.wrBtns {
    width: 170px;
    margin-left: 20px;
}

.body {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 30px;
    padding: 30px 0 15px;
}

.input {
    input {
        height: 42px;
        font-size: 14px;
        padding: 0 10px;
    }
}

.prices {
    padding: 20px 0;
}

.pricesItem {
    display: grid;
    grid-template-columns: 1fr 4fr 2fr;
    column-gap: 30px;
}

.city,
.radioTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #2d303e;
}

.city {
    padding-top: 38px;
    text-align: right;
}

.radioTitle {
    span {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        color: #676767;
    }
}

.pricesInputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 30px;
    align-items: center;
}

.pricesRadioButtons, .comboBoxSettings {
    display: flex;
    flex-direction: column;

    &.comboBoxSettings {
        max-width: 65%;
    }
}

.radioButtonsItem {
    display: flex;
    align-items: center;
    height: 54%;
    color: #2d303e;

    span {
        font-size: 14px;
    }
}

.firstRadio {
    height: min-content;
    margin-top: 8px;
}

.comboBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
}

.fieldset {
    border: 1px solid #DEDEDE;
    border-radius: 8px;
}
