.modalContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 20000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
}
