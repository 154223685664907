@import "../../../assets/styles/index.scss";

@mixin defaultTabStyles {
    border: 1px solid $PRIMARY_ORANGE;
    padding: 4px 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 14px;
    font-weight: 500;
    color: $PRIMARY_ORANGE;
    white-space: nowrap;
    text-align: center;
    display: flex;
    align-items: center;
    background: transparent;
    &:hover {
        color: green;
    }
    &.active {
        background: $PRIMARY_ORANGE;
        color: $TEXT_PRIMARY_LIGHT;
        user-select: none;
    }
    &:active {
        border-color: green;
    }
}

.wr {
    padding-bottom: 8px; // 10px
    border-bottom: 1px solid $PRIMARY_ORANGE;
    &.borderless {
        border-bottom: none;
    }
    display: flex; 
    justify-content: space-between;
}

.ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 20px;

    &.marginless > li {
      margin-bottom: 0;  
    }
}

.li {
    margin-bottom: 10px;
    &:not(:last-of-type) {
        margin-right: 7px;
    }
}

.a {
    @include defaultTabStyles;
}

.icon {
    font-size: 0;
    margin-right: 10px;
    svg {
        font-size: 16px;
    }
}

.icon_marg {
    margin-right: 0;
}

.active {
    background: $PRIMARY_ORANGE;
    color: $TEXT_PRIMARY_LIGHT;
    user-select: none;
}

.btns_r {
    display: flex;
    gap: 20px;
}