.ReactModalPortal {}

.modal__opas {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    /* см. ниже */
    align-items: center;
    z-index: 220000;
    background: rgba(0, 0, 0, 0.5);
}

.modal__wrapp {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    min-height: calc(100% - 100px);
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    margin: 50px 0;
    flex-shrink: 0;
}

.MuiPopper-root {
    z-index: 10000000 !important;
}

.rdrDayDisabled {
    background-color: transparent !important;
}

.rhap_container {
    padding: 10px !important;
}

.m-kitchen-counter {
    .moda-contest {
        width: 100%;
        max-width: 1500px;

        .moda-contest__wr {
            height: calc(100vh - 200px);
            display: flex;
            flex-direction: column;
        }
    }
}

.m-courier-report,
.m-kitchen-delivery-time,
.m-client-info {
    .moda-contest {
        width: max-content;
        max-width: 1500px;

        .moda-contest__wr {
            height: calc(100vh - 200px);
            display: flex;
            flex-direction: column;
        }
    }
}

.m-client-info {
    .moda-contest {
        width: 80% !important;
    }
}

.m-kitchen-delivery-time {
    .moda-contest {
        .moda-contest__wr {
            height: auto !important;
        }
    }
}

.head-modal-f {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    border-bottom: 1px solid #EDA240;
    align-items: center;

    &__title {
        text-align: left;
        font-size: 18px;
        font-weight: 700;
    }
}

.filter-model {
    display: flex;
    align-items: center;
    gap: 20px;
}

.cont-modal-sc {
    flex: 1 1 auto;
    margin-top: 20px;
    overflow-y: auto;
}

.cont-modal-info {

    table,
    th,
    td {
        border: 1px solid;
    }

    table {
        border-collapse: collapse;

        &.close-table {
            tr {
                &:not(:first-child) {
                    display: none;
                }
            }
        }
    }

    td {
        max-width: 150px;

        span {
            font-weight: 500;
        }
    }

    th,
    td {
        padding: 4px 10px;
    }

    .btn-shows {
        cursor: pointer;
        text-align: center;
        background: #eda240;
        height: 13px;

        svg {
            width: 13px;
            height: 13px;
        }

        margin-right: 10px;

        &:hover {
            background: blue;
            color: #fff;
        }
    }

    @media print {
        padding: 20px;
    }
}