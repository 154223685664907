@import "../../assets/styles/index.scss";

.statisticpage {
    min-width: 100%;

    &__couriers {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: $TEXT_SECONDARY_LIGHT;
        overflow-y: scroll;

        p {
            line-height: 25px;
        }
    }

    &__courier {
        margin-right: 10px;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        background-color: $PRIMARY_DARK;

        &:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }

    &__select {
        width: 320px;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 20001;

        &_main {
            position: relative;
            z-index: 10;
            background-color: #1F1D2B;
            height: 100%;
        }
    }
}

.modal_wr {
    margin-top: 40px;
}

.opas {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    background: rgba(255, 255, 255, 0.65);
}

.hed {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_r {}
}

.contain {
    width: 100%;
}

.wr_head {
    margin-bottom: 20px;
}

.block {
    margin-top: 20px;
}

.wr_bot {
    margin-top: 20px;
}

.text_info {
    text-align: center;
    color: grey;
    padding: 40px 0;
    font-size: 16px;
}

.slick {
    padding: 0 13px 16px 16px;
    max-height: calc(100% - 58px);
    height: calc(100% - 58px);
    overflow-y: auto;
    margin-right: 3px;
    margin-top: 16px;
}

.rows_dashboard {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
}

.rows_dashboard_4_2 {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
}

.styles.col_2 {}

.title_col {
    font-size: 20px;
    font-weight: 600;
}

.title_p {
    font-size: 16px;
    font-weight: 600;
    padding-right: 30px;

    span {
        font-size: 20px;
        color: #eda240;
    }
}

.title_p_n {
    span {
        font-size: 16px;
    }
}

.wr_item {
    margin-top: 20px;
}

.row_two_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.row_two_btn_marg_top {
    margin-top: 16px;
}

.contain_main {
    margin-top: 20px;
}


.headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 10px 10px;
    border-bottom: 1px solid #E6E6E6;
}

.btn_close {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #eda240;
    border-radius: 50%;
    background-color: transparent;

    &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.2);
    }
}

.title_white {
    color: #fff;
}

.col_2_h {
    min-height: 60vh;
}

.row_map_and {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-top: 20px;
}

.items_radio {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 100vw;
    padding-right: 20px;
    overflow-y: auto;

    p {
        font-size: 16px !important;
        padding-bottom: 4px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    label {
        margin-top: 0;
        margin-left: 0;
        margin-right: 6px;

        &>span {
            padding: 0;
        }
    }

    fieldset {
        display: flex;
        align-items: center;
        flex-direction: row;
        & > p {
            width: 120px;
        }
    }
}

.mape {
    width: 100%;
    padding-bottom: 100%;
    position: relative;

    &>div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.btn_bot_mape {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}