@import "../../../assets/styles/index.scss";

.product {
    width: 100%;
    min-height: 290px;
    background-color: $PRODUCT_BG;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.text {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.title {
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #fff;
}

.weight {
    font-size: 15px;
    line-height: 130%;
    text-align: center;
    color: #9d9d9d;
    margin-top: 10px;
}

.editButton {
    border: 0;
    background: rgba($color: #eda240, $alpha: 0.2);
    border-radius: 0;

    &:hover {
        border: 0;
    }
}

.rwEditButton {
    transition: 0.4s;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        transition: 0.4s;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #eda240;
        color: #fff;
        padding: 12px;
        span {
            padding-left: 10px;
        }
        &:hover {
            background: green;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
}

.front {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .frontTop {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        flex: 1;

        &:hover {
            .back {
                top: 0;
            }
        }
    }

    .imageContainer {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .productImage {
        height: 100%;
    }

    &:hover {
        .rwEditButton {
            margin-bottom: 0;
        }
        button {
            width: 100%;
            border-radius: 0 !important;
        }
    }
}

.back {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    background-color: $PRODUCT_BG;
    width: 100%;
    height: 290px;
    transition: all 0.2s;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .backTop {
        width: 100%;
        height: 85%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;

        .restaurantLogo {
            width: 40px;
            height: 40px;
        }

        .title {
            margin-top: 10px;
        }

        .description {
            color: #aaaaaa;
            font-size: 14px;
            line-height: 130%;
            text-align: center;
            display: -webkit-box;
            max-width: 200px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-top: 10px;
        }
    }

    .backBottom {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 10px;
    }
}
