@import "../../../assets/styles/index.scss";

.wr_calendar {
}

.main_cal {
    display: none;
}

.main_cal_active {
    display: block;
}

.wr_in {
    position: relative;
    display: flex;
    align-items: flex-end;
    & > div {
        &:first-child {
            flex: 1 1 auto;
        }
    }
}

.bnt_close_cal {
    width: 40px;
    height: 40px;
    min-width: 40px;
    display: flex;
    align-items: center;
    background: red;
    color: #fff;
    padding: 4px;
    border-radius: 8px;
    cursor: pointer;
    justify-content: center;
    margin: 3px 0 3px 10px;
}
