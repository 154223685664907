.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px 10px 0;
    border-bottom: 1px solid #393c49;

    &__name {
        font-size: 20px;
        color: #fff;
    }

    &__close {
        cursor: pointer;
    }
}