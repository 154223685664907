@import "../../assets/styles/index.scss";

.header_top {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.loader {
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.flex_end {
    display: flex;
    justify-content: flex-end;
}

.w_100 {
    width: 100%;
}

.products {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.body {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 30px;
    row-gap: 30px;
    width: 100%;
}

.createProductButton {
    background-color: $PRODUCT_BG;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.1s;

    &:hover {
        box-shadow: 0 3px 15px 5px grey;
        transform: scale(1.01);
    }
}

.plus {
    font-size: 32px;
    color: $PRIMARY_ORANGE;
    margin-bottom: 15px;
    transition: all 0.3s;
}

.createProductButtonText {
    color: $PRIMARY_ORANGE;
    text-align: center;
}

.row_jus {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_cent {
    display: flex;
    gap: 20px;
    align-items: center;
}