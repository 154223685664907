@import "../../assets/styles/constants.scss";

@mixin animate($animation, $duration, $method, $times) {
    animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}

.wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;

    @include keyframes(slide-in) {
        from {
          transform: translateY(100%);
        }
        to {
          transform: translateY(0);
        }
    }

    @include animate(slide-in, 0.15s, linear, 1);
}

:global(.simple-keyboard).smaki {
    background-color: darken($PRODUCT_BG, 1.5);
    border-radius: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
  
:global(.simple-keyboard).smaki :global(.hg-button) {
    height: 5.5vh;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $PRODUCT_BG;
    color: $PRIMARY_LIGHT;
    border-bottom: none;
    font-family: 'Inter', sans-serif;
}
  
:global(.simple-keyboard).smaki :global(.hg-button):active {
    background: $PRIMARY_ORANGE;
    color: $PRIMARY_LIGHT;
}
  
#root :global(.simple-keyboard).smaki + :global(.simple-keyboard-preview) {
    background: $PRIMARY_ORANGE;
}
  