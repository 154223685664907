.wr {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    color: #1F1D2B;
    text-align: center;
}

.text {
    margin-top: 20px;
    font-size: 16px;
}

.error {
    font-size: 100px;
    font-weight: 700;
}
