.wrapper {
    position: absolute;
    top: 0;
    right: -1000%;
}

.main {
    max-width: 407px;
    width: 100%;
    background: #fff;
    padding: 10px 20px 20px 20px;
}

.restorant {
    text-align: center;
    font-size: 26px;
    border-top: 1px dotted #000;
    padding-top: 5px;
}

.hrs {
    border-top: 1px dotted #000;
    height: 1px;
    display: block;
    background: transparent;
    line-height: 0;
    font-size: 0;
    margin-bottom: 4px;
}

.hrs_bot {
    border-top: 1px dotted #000;
    height: 1px;
    display: block;
    background: transparent;
    line-height: 0;
    font-size: 0;
    margin-top: 4px;
}

.invoice {
    text-align: center;
    font-size: 22px;
}

.order {
    font-size: 26px;
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
}

.address_client {
    font-size: 26px;
    font-weight: 500;
    margin-top: 10px;
    text-align: center;

    span {
        display: block;
        text-align: center;
    }
}

.client {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    margin-top: 10px;
}

.address_info {
    margin-top: 10px;
    font-size: 20px;
}

.address_info_item {
    display: flex;

    span {
        &:first-child {
            width: 100px;
        }
    }
}

.comment {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 500;

    span {
        font-weight: bolder;
        padding-right: 10px;
    }
}

.additionally {
    margin-top: 10px;
    font-size: 20px;

    span {
        &:first-child {
            padding-right: 5px;
        }
    }
}

.transferred_kitchen {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 500;

    span {
        &:first-child {
            padding-right: 5px;
        }
    }
}

.person {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;

    span {
        &:first-child {
            padding-right: 5px;
        }
    }
}

.cutlery {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;

    span {
        &:first-child {
            padding-right: 5px;
        }
    }
}

.table {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
}

.table__row {
    display: flex;
}

.table__row_head {
    padding-bottom: 4px;
    border-bottom: 1px dotted #000;
    margin-bottom: 5px;
}

.table__item_name {
    width: 48%;
}

.table__item_number {
    width: 17.33%;
}

.table__item_price {
    width: 17.33%;
}

.table__item_sum {
    width: 17.33%;
}

.table__row_prod {
    padding-bottom: 4px;
}

.table__row_items {
    display: flex;
    justify-content: flex-end;

    .tables__item {
        min-width: 17.33%;
        text-align: right;
        padding: 0 3px;
    }
}

.table__items_number {}

.table__items_price {}

.table__items_sum {}

.bot {
    padding-top: 8px;
    border-top: 1px dotted #000;
    border-bottom: 1px dotted #000;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 8px;

    span {
        &:last-child {
            padding-left: 15px;
        }
    }
}

.discount {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px dotted #000;
}

.discount__title {
    font-weight: 500;
    font-size: 20px;
}

.discount__row {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
}

.discount__name {
    font-size: 20px;
    padding-right: 20px;
}

.discount__item {
    font-size: 20px;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
}

.bonuses {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px dotted #000;
}

.bonuses__row {
    display: flex;
    justify-content: space-between;
}

.bonuses__name {
    font-size: 21px;
    padding-right: 20px;
    font-weight: 500;
}

.bonuses__item {
    font-size: 21px;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
}

.bot_payment {
    padding-top: 8px;
    border-bottom: 1px dotted #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-weight: 700;
    font-size: 30px;
    padding-bottom: 8px;

    span {
        &:last-child {
            padding-left: 15px;
        }
    }
}

.type_payments {
    padding-top: 8px;
    border-bottom: 1px dotted #000;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 21px;
    padding-bottom: 8px;

    span {
        &:last-child {
            padding-left: 15px;
        }
    }
}

.rest {
    padding-top: 8px;
    border-bottom: 1px dotted #000;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 24px;
    padding-bottom: 8px;

    span {
        &:last-child {
            padding-left: 15px;
        }
    }
}

.bot_text {
    border-top: 1px dotted #000;
    text-align: center;
    font-weight: 500;
    font-size: 21px;
    margin-top: 4px;
    padding-top: 8px;
    padding-bottom: 400px;
}

.table__row_prod_left {
    padding-left: 20px;
}

.type_payments_diw {
    flex-direction: column;

    &>span {
        &:nth-child(1) {
            width: 100%;
        }

        &:nth-child(2) {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-left: 0;
            margin-left: 0;

            &> {
                span {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding-left: 0;
                    margin-left: 0;

                    span {
                        width: 50%;

                        &:nth-child(1) {
                            text-align: left;
                        }

                        &:nth-child(2) {
                            padding-left: 10px;
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}

.head_kitchen {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    padding: 6px 0;
    border-width: 1px 0 1px 0;
    border-style: dashed;
    border-color: #000;
}

.mt_rowe {
    margin-top: 25px;
}

.title_center {
    font-size: 24px;
    text-align: center;
}

.it_li {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 5px;

    span {
        &:nth-child(2) {
            padding-left: 10px;
            text-align: right;
        }
    }
}

.ul_li {
    &:not(:first-child) {
        margin-top: 20px;
    }
}

.text_center {
    font-size: 18px;
    text-align: center;
}