@import "../../assets/styles/index.scss";

.wr {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left {
    padding-right: 20px;
}

.title {
    font-weight: 600;
    font-size: 20px;
}

.right {
    display: flex;
    align-items: center;
}

.wr_item {
    margin-right: 16px;
}

.head_in_btn {
    display: flex;
    align-items: flex-end;
    gap: 20px;
}

.quantity_link {
    padding-bottom: 12px;
}