.column {
    display: flex;
    flex-direction: column;

    &:not(.padless) {
        padding: 10px;
    }
}

.spaceBetween {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.header {
    padding: 1rem !important;
    font-size: 16px;
}

.group {
    font-family: Arial, Helvetica, sans-serif;

    font-size: 12px;
    color: #465666;

    border: 1px solid #E0E0E0;
    border-radius: 8px;

    & legend {
        font-weight: bold;

        background-color: #EEEEEE;
        border-bottom: 1px solid #E0E0E0;

        padding: 0.25rem 1rem 0.25rem 1rem;

        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }

}

.container {
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto); 
    
    gap: 10px;

    & :last-child {
        grid-column: 2;
        grid-row: -1 / 1;
    }
}

.paddedLeft {
    padding-left: 1rem;
}

.statisticsHeader {
    text-align: center;
    padding-bottom: 0.25rem;
}



