.container {
  display: flex;
  gap: 10px;
  max-height: 30vh;

  & .kitchens {

    &,
    & ul {
      border-radius: 8px 0 0 8px;
      cursor: pointer;
    }

    overflow: auto;
  }
}