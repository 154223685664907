$PRIMARY_DARK: #23262a;
$PRIMARY_LIGHT: #fafafa;
$PRIMARY_ORANGE: #eda240;
$TEXT_PRIMARY_LIGHT: rgba(256, 256, 256, 1);
$TEXT_SECONDARY_LIGHT: rgba(256, 256, 256, 0.7);
$TEXT_PRIMARY_DARK: #252525;
$TEXT_SECONDARY_DARK: #425466;
$DISABLED_TAB_TEXT: grey;
$PRODUCT_BG: #1F1D2B;
$RED: #c21d3b;
