@import "../../../assets/styles/index.scss";

.btnType {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    color: #000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c7c2c2;

    &:hover {
        background:  #eda240;
        color: #fff;
    }
}
