@import "../../assets/styles/constants.scss";

.alert {
    z-index: 999;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding-left: 14px;
    padding-right: 14px;

    position: fixed;
    margin-top: 50px;
    text-align: center;

    width: 100%;
    height: 35px;
    color: $PRIMARY_LIGHT;
    font-family: 'Inter', sans-serif;
    background-color: $RED;
    font-size: 1.5em;
}

.hardRefreshButton {
    width: 15%;
    height: 85%;
    background-color: $PRIMARY_ORANGE;
    color: $PRIMARY_LIGHT;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}
