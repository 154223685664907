@import "../../../assets/styles/index.scss";

.usersModal {
    width: 33.333%;
    overflow: hidden;
    position: relative;
    overflow-y: scroll;
    position: relative;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px 10px 0;
    border-bottom: 1px solid #393c49;
    padding-bottom: 30px;
    margin-bottom: 30px;

    &__name {
        font-size: 20px;
        color: #fff;
    }

    &__close {
        cursor: pointer;
    }
}

.footer {
    padding: 20px 0 0 0;
    width: 100%;

    &__line {
        width: 100%;
        height: 1px;
        background: #393c49;
        margin-bottom: 10px;
    }
}

.footer_row {
    display: flex;
    justify-content: right;
}

.footer_row_top {
    margin-bottom: 15px;
}

.row_add_delete {
    display: flex;
    padding-bottom: 12px;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
}

.btn_text {
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    background: transparent;
    font-weight: 300;
    &:hover {
        color: #eda240;
    }
}