.head, .spacebetween {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  & .leftmargin {
    margin-left: 10px;
  }
}

.itemlist {
  display: flex;
  margin-top: 10px;
}
