.container {
  display: flex;
  gap: 10px;
  max-height: 60vh;

  & .kitchens {
    &, & ul {
      border-radius: 8px 0 0 8px;
      cursor: pointer;
    }
    overflow: auto;
  }

  & .information {
    position: relative;

    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    overflow: auto;

    & .item {
      display: flex;
      min-height: 15vh;
      padding: 10px;
      gap: 10px;
      border: 1px solid #dedede;
      border-radius: 12px;
      align-items: center;
      justify-content: space-evenly;
    }
  }
}
