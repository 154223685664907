.fieldset {
    border: 1px solid #DEDEDE;
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 8px;
    margin-top: 10px;
}

.modalBody {
    padding: 8px 0 8px 0;
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.btnWrapper {
    display: flex;
    margin-top: 10px;
    width: 100%;
    justify-content: flex-end;

    gap: 10px;

    // & button {
        // width: min-content;
    // }
}

// .row {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// }

.goodsReceivementType {
    display: flex;
    gap: 8px;
}
